import styled from "@emotion/styled";
import {
  MenuItem,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { ImageResponse } from "../../../clients/services/sams/gallery/types";
import { SunProduct, ArticleType } from "../../../clients/services/sams/types";
import generateSlugField from "../../../constants/generateSlugField";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import ImageSelection from "../../ImageSelection";
import RichContentEditor from "../../RichContentEditor";
import SelectField from "../../SelectField";
import StatusSelector from "../../StatusSelector";
import TabPanel from "../../TabPanel";

import { todaysDate, todaysPlus1000YrsDate } from "./utils";

const CreateArticleContainer = styled.div`
  padding-top: 15px;
`;
const CustomInputField = styled(TextField)`
  margin-top: 24px;
`;
const DatesInputFlexer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DateInput = styled(TextField)`
  margin-top: 24px;
  width: 47%;
`;
const CustomFormControl = styled(SelectField)`
  margin-top: 24px;
  width: 100%;
`;

interface FormInterface {
  values: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormInterface> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting
}) => {
  const [value, setValue] = useState(0);
  const handleTabChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  useEffect(() => {
    if (formik.values.articleType === ArticleType.FAVOURITES) {
      formik.setFieldValue("publishStartDate", todaysDate);
      formik.setFieldValue("publishEndDate", todaysPlus1000YrsDate);
    }
    if (formik.values.articleType === ArticleType.FAVOURITES && value === 1) {
      setValue(0);
    }
  }, [formik.values.articleType]);

  return (
    <CreateArticleContainer data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        <Grid container item xs={8}>
          <form id={formId} onSubmit={formik.handleSubmit}>
            {formik.submitCount !== 0 && (
              <ErrorMessage formValidationErrors={formik.errors} />
            )}
            <ImageSelection
              id="image"
              value={formik.values.image}
              imageResizeMessage={imageResizeMessages.article}
              error={formik.touched.image && Boolean(formik.errors.image)}
              helperText={formik.touched.image && formik.errors.image}
              btnText={
                formik.values.image.length !== 0
                  ? "Select another image"
                  : "Select an image"
              }
              setFieldValue={formik.setFieldValue}
              setImageFieldsValues={(data: ImageResponse) => {
                if ("imageSmall" in data) {
                  formik.setFieldValue("image", data.image);
                  formik.setFieldValue("imageSmall", data.imageSmall);
                }
              }}
              isImageSelectionDisabled={!formik.values.headline}
              isImageSelected={formik.values.image.length !== 0}
              postType="article"
              imageToUpload={formik.values.image}
              postHeadline={formik.values.code}
            />
            <CustomFormControl
              form={formik}
              label={"Sun Product"}
              labelID={"labelProductId"}
              name={"sunProduct"}
              defaultValue={formik.values.sunProduct}
            >
              <MenuItem value={SunProduct.SUN_MOBILE} data-testid="Sun Mobile">
                Sun Mobile
              </MenuItem>
              <MenuItem value={SunProduct.SUN_SAVERS} data-testid="Sun Savers">
                Sun Savers
              </MenuItem>
              <MenuItem
                value={SunProduct.MEMBERS_ENCLOSURE}
                data-testid="Members_Enclosure"
              >
                Members Enclosure
              </MenuItem>
              <MenuItem value={SunProduct.SUN_CLUB} data-testid="Sun Club">
                Sun Club
              </MenuItem>
            </CustomFormControl>
            {formik.values.sunProduct === SunProduct.MEMBERS_ENCLOSURE && (
              <CustomFormControl
                form={formik}
                label={"Article Type"}
                labelID={"labelProductId"}
                name={"articleType"}
                defaultValue={formik.values.articleType}
              >
                <MenuItem value={ArticleType.STANDARD} data-testid="STANDARD">
                  STANDARD
                </MenuItem>
                <MenuItem
                  value={ArticleType.FAVOURITES}
                  data-testid="FAVOURITES"
                >
                  FAVOURITES
                </MenuItem>
              </CustomFormControl>
            )}
            <TextField
              fullWidth
              disabled
              id="tag"
              name="tag"
              label="Tag"
              variant="standard"
              value={
                formik.values.tag ||
                "Will be generated automatically after saving"
              }
            />
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="Article Sections"
            >
              <Tab value={0} label="Info" data-testid="infoTab" />
              {formik.values.articleType === "STANDARD" && (
                <Tab value={1} label="Settings" data-testid="settingsTab" />
              )}
              <Tab value={2} label="Copy" data-testid="copyTab" />
            </Tabs>
            <TabPanel value={value} index={0}>
              {formik.values.articleType === "FAVOURITES" &&
                formik.values.sunProduct === SunProduct.MEMBERS_ENCLOSURE && (
                  <CustomInputField
                    fullWidth
                    id="favouritesCta"
                    name="favouritesCta"
                    label="Favourites CTA"
                    variant="standard"
                    value={formik.values.favouritesCta}
                    onChange={(e) => {
                      formik.setFieldValue("favouritesCta", e.target.value);
                      generateSlugField(e.target.value, formik);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.favouritesCta &&
                      Boolean(formik.errors.favouritesCta)
                    }
                    helperText={
                      formik.touched.favouritesCta &&
                      formik.errors.favouritesCta
                    }
                  />
                )}
              <CustomInputField
                fullWidth
                id="headline"
                name="headline"
                label="Headline"
                variant="standard"
                value={formik.values.headline}
                onChange={(e) => {
                  formik.setFieldValue("headline", e.target.value);
                  generateSlugField(e.target.value, formik);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.headline && Boolean(formik.errors.headline)
                }
                helperText={formik.touched.headline && formik.errors.headline}
              />
              <CustomInputField
                fullWidth
                id="shortHeadline"
                name="shortHeadline"
                label="Short Headline"
                variant="standard"
                value={formik.values.shortHeadline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.shortHeadline &&
                  Boolean(formik.errors.shortHeadline)
                }
                helperText={
                  formik.touched.shortHeadline && formik.errors.shortHeadline
                }
              />
              <CustomInputField
                fullWidth
                id="badgeText"
                name="badgeText"
                label="Badge Text"
                variant="standard"
                value={formik.values.badgeText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.badgeText && Boolean(formik.errors.badgeText)
                }
                helperText={formik.touched.badgeText && formik.errors.badgeText}
              />
              <CustomInputField
                fullWidth
                id="sell"
                name="sell"
                label="Sell"
                variant="standard"
                value={formik.values.sell}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.sell && Boolean(formik.errors.sell)}
                helperText={formik.touched.sell && formik.errors.sell}
              />
              <CustomInputField
                fullWidth
                id="slug"
                name="slug"
                label="Slug"
                variant="standard"
                value={formik.values.slug}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
              />
              {formik.values.sunProduct === SunProduct.MEMBERS_ENCLOSURE && (
                <CustomInputField
                  fullWidth
                  id="videoId"
                  name="videoId"
                  label="Video Id"
                  variant="standard"
                  value={formik.values.videoId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.videoId && Boolean(formik.errors.videoId)
                  }
                  helperText={formik.touched.videoId && formik.errors.videoId}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DatesInputFlexer>
                <DateInput
                  id="publishStartDate"
                  name="publishStartDate"
                  label="Start Date"
                  type="datetime-local"
                  variant="standard"
                  value={formik.values.publishStartDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.publishStartDate &&
                    Boolean(formik.errors.publishStartDate)
                  }
                  helperText={
                    formik.touched.publishStartDate &&
                    formik.errors.publishStartDate
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <DateInput
                  id="publishEndDate"
                  name="publishEndDate"
                  label="End Date"
                  type="datetime-local"
                  variant="standard"
                  value={formik.values.publishEndDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.publishEndDate &&
                    Boolean(formik.errors.publishEndDate)
                  }
                  helperText={
                    formik.touched.publishEndDate &&
                    formik.errors.publishEndDate
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </DatesInputFlexer>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <RichContentEditor
                id="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.setFieldValue}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              {formik.values.articleType === "STANDARD" && (
                <RichContentEditor
                  id="tandcs"
                  label="Terms and Conditions"
                  value={formik.values.tandcs}
                  onChange={formik.setFieldValue}
                  error={formik.touched.tandcs && Boolean(formik.errors.tandcs)}
                  helperText={formik.touched.tandcs && formik.errors.tandcs}
                />
              )}
            </TabPanel>
          </form>
        </Grid>
        <Grid container item xs={4}>
          <StatusSelector
            form={formik}
            actionLabel={actionLabel}
            testId={actionTestId}
            isSubmitting={isSubmitting}
          />
          {values.id ? (
            <HistoryTable postType={"article"} postId={values.id} />
          ) : null}
        </Grid>
      </Grid>
    </CreateArticleContainer>
  );
};

export default Form;
