import { Article as FormDataArticleInteface } from "../clients/services/sams/content/article/types";
import { PromotionType } from "../clients/services/sams/promotion/types";
import { ArticleType, SunProduct } from "../clients/services/sams/types";
import { FormDataInterface as FormDataOfferInterface } from "../components/Offer/types";

export enum Tags {
  OFFER_STANDARD_ARTICLE = "Offer: Standard Article",
  OFFER_FAVOURITES_ARTICLE = "Offer: Favourites Article",
  OFFER_OTHER_CODE_COLLECT = "Offer: Other Code collect",
  OFFER_FREE_RACING_TICKETS = "Offer: Free racing tickets",
  OFFER_SUPERDAYS = "Offer: Superdays",
  OFFER_950_HOLS = "Offer: 9.50 hols",
  OFFER_OTHER_NON_CODE = "Offer: Other Non-code",
  OFFER_COMPETITION = "Offer: Competition",
  UNKNOWN = "Unknown tag"
}

export const getArticleTrackingTag = (data: FormDataArticleInteface) => {
  switch (data.articleType) {
    case ArticleType.FAVOURITES:
      return Tags.OFFER_FAVOURITES_ARTICLE;
    case ArticleType.STANDARD:
      return Tags.OFFER_STANDARD_ARTICLE;
    default:
      return Tags.UNKNOWN;
  }
};

export const getOfferTrackingTag = (data: FormDataOfferInterface) => {
  if (
    data.sunProduct === SunProduct.SUN_SAVERS ||
    data.sunProduct === SunProduct.SUN_CLUB
  ) {
    if (data.attractionName) {
      return Tags.OFFER_SUPERDAYS;
    }
    if (data.redemptionLinkUrl?.includes("@GFM_CHECKSUM@")) {
      return Tags.OFFER_950_HOLS;
    }
  }
  if (
    data.sunProduct === SunProduct.MEMBERS_ENCLOSURE &&
    data.redemptionLinkUrl?.includes("@STRETCH_CHECKSUM@")
  ) {
    return Tags.OFFER_FREE_RACING_TICKETS;
  }
  if (data.promotionType === PromotionType.REGULAR) {
    return Tags.OFFER_OTHER_CODE_COLLECT;
  }
  if (data.promotionType === PromotionType.NONCODE) {
    return Tags.OFFER_OTHER_NON_CODE;
  }
  if (data.promotionType === PromotionType.COMPETITION) {
    return Tags.OFFER_COMPETITION;
  }
  return Tags.UNKNOWN;
};
