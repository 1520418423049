import { DateTime } from "luxon";
import moment from "moment";

import {
  OfferRedemptionMechanic,
  Promotion,
  PromotionType
} from "../../clients/services/sams/promotion/types";
import { Status } from "../../clients/services/sams/types";
import { FormModelTransformer } from "../../hooks/useFormData/types";
import { formatDate } from "../utils/dateTimezoneFormatter";

import { FormDataInterface } from "./types";

class Transformer implements FormModelTransformer {
  private formDataTemplate;

  constructor(formDataTemplate: FormDataInterface) {
    this.formDataTemplate = formDataTemplate;
  }

  public getFormDataTemplate = () => {
    return this.formDataTemplate;
  };

  public formDataToModel = (
    formData: FormDataInterface,
    isUpdateOperation?: boolean,
    hasProductOfTypeOffer?: boolean
  ) => {
    const {
      previewCpns,
      actions,
      dnaActions,
      internalReview,
      promotionType,
      codeCollectStartDate,
      codeCollectEndDate,
      redemptionStartDateTime,
      redemptionEndDateTime,
      bookingCodeEndDate,
      alwaysOn,
      allowMultipleRedemption,
      redemptionMechanic,
      publicationStatus,
      image,
      imageSmall,
      ...nonTransformedData
    } = formData;

    const hasRedemptionStartDate =
      promotionType !== PromotionType.COMPETITION &&
      promotionType !== PromotionType.WALLET;
    return {
      promotionType,
      redemptionStartDateTime:
        redemptionStartDateTime && hasRedemptionStartDate
          ? new Date(redemptionStartDateTime).toISOString().slice(0, 16)
          : "",
      redemptionMechanic,
      ...nonTransformedData,
      // these date fields are not nullable in the DBs,
      // but some post types don't include them
      codeCollectStartDate:
        promotionType === PromotionType.NONCODE ||
        promotionType === PromotionType.DONATION
          ? new Date(redemptionStartDateTime).toISOString().slice(0, 16)
          : codeCollectStartDate,
      codeCollectEndDate:
        promotionType === PromotionType.NONCODE ||
        promotionType === PromotionType.DONATION
          ? new Date(redemptionStartDateTime).toISOString().slice(0, 16)
          : codeCollectEndDate,
      actions: JSON.stringify(actions),
      dnaActions:
        promotionType === PromotionType.REGULAR ||
        promotionType === PromotionType.COMPETITION
          ? JSON.stringify(dnaActions)
          : "[]",
      previewCpns: JSON.stringify(
        internalReview && previewCpns
          ? previewCpns.split(",").map((item: string) => item.trim())
          : []
      ),
      alwaysOn: promotionType !== PromotionType.NONCODE ? false : alwaysOn,
      redemptionEndDateTime:
        alwaysOn && promotionType === PromotionType.NONCODE
          ? moment().add(5, "y").format("YYYY-MM-DD[T]HH:mm:ss")
          : new Date(redemptionEndDateTime).toISOString().slice(0, 16),
      bookingCodeEndDate: bookingCodeEndDate
        ? new Date(bookingCodeEndDate).toISOString().slice(0, 16)
        : "",
      allowMultipleRedemption:
        promotionType === PromotionType.COMPETITION
          ? false
          : allowMultipleRedemption,
      publicationStatus:
        (redemptionMechanic === OfferRedemptionMechanic.PRODUCT &&
          publicationStatus !== Status.DELETED &&
          isUpdateOperation === false) ||
        (redemptionMechanic === OfferRedemptionMechanic.PRODUCT &&
          isUpdateOperation === true &&
          hasProductOfTypeOffer === false)
          ? Status.DRAFT
          : publicationStatus,
      featuredImage: image,
      featuredImageSmall: imageSmall
    };
  };

  public modelToFormData = (modelData: Promotion) => {
    const {
      previewCpns,
      redemptionAllocation,
      showMarketingPermissions,
      redemptionStartDateTime,
      redemptionEndDateTime,
      bookingCodeEndDate,
      featuredImage,
      featuredImageSmall,
      ...nonTransformedData
    } = modelData;
    return {
      ...this.formDataTemplate,
      ...nonTransformedData,
      internalReview: previewCpns?.length > 0 || false,
      previewCpns: previewCpns ? previewCpns.join(",") : "",
      isUpdateOperation: true,
      maximumRedemptions:
        redemptionAllocation?.total || this.formDataTemplate.maximumRedemptions,
      showMarketingPermissions: showMarketingPermissions || false,
      redemptionStartDateTime: redemptionStartDateTime
        ? formatDate(redemptionStartDateTime).slice(0, 16)
        : "",
      redemptionEndDateTime: redemptionEndDateTime
        ? formatDate(redemptionEndDateTime).slice(0, 16)
        : "",
      bookingCodeEndDate: bookingCodeEndDate
        ? formatDate(bookingCodeEndDate).slice(0, 16)
        : "",
      image: featuredImage,
      imageSmall: featuredImageSmall
    };
  };
}

export default Transformer;
