import { useQuery } from "react-query";

import { listMorePages } from "../../../clients/services/sams/content/morePage";
import { Status } from "../../../clients/services/sams/types";

export const useListMorePages = () => {
  const { isLoading, data, error, refetch } = useQuery(["listMorePages"], () =>
    listMorePages()
  );

  const morePageData = data?.filter(
    (morePage) => morePage.status !== Status.DRAFT
  );

  return { isLoading, data: morePageData ?? [], error, refetch };
};
