import styled from "@emotion/styled";
import { Chip, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { Draggable } from "react-beautiful-dnd";

import { MorePage } from "../../../clients/services/sams/content/morePage";
import { MorePageType } from "../../../clients/services/sams/types";

const DropTableCell = styled(TableCell)`
  width: 35px;
  font-size: 24px;
  text-align: center;
`;
const ChipTableCell = styled(TableCell)`
  display: table-cell;
  gap: 12px;
`;

interface MorePageElementInterface {
  entity: MorePage;
  index: number;
}

const MorePageTableCell: FC<MorePageElementInterface> = ({ entity, index }) => {
  return (
    <Draggable
      draggableId={
        Object.hasOwn(entity, "slug") ? entity.slug : (entity.url as string)
      }
      index={index}
    >
      {(innerProvider: any) => (
        <TableRow
          {...innerProvider.draggableProps}
          ref={innerProvider.innerRef}
        >
          <DropTableCell
            data-testid={`DraggableMorePage${index}TestID`}
            {...innerProvider.dragHandleProps}
          >
            {" "}
            ={" "}
          </DropTableCell>
          <TableCell data-testid={`DraggableMorePageTitle${index}`}>
            {entity.headline}
          </TableCell>
          <ChipTableCell>
            {entity.type === MorePageType.INTERNAL_PAGE && (
              <Chip
                color="secondary"
                label="Internal Page"
                variant="filled"
                data-testid="morePageTypeTestIDInternalPage"
              />
            )}
            {entity.type === MorePageType.EXTERNAL_LINK && (
              <Chip
                color="primary"
                label="External Link"
                variant="filled"
                data-testid="morePageTypeTestIDExternalLink"
              />
            )}
          </ChipTableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

export default MorePageTableCell;
