import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import {
  changeMorePagePosition,
  MorePage
} from "../../../clients/services/sams/content/morePage";
import { setNotification } from "../../../state/reducers/notification";

interface MutationContext {
  previousMorePages: MorePage[];
}

export function useChangeMorePagePosition() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(changeMorePagePosition, {
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries("getMorePages");

      const previousMorePages =
        queryClient.getQueryData<MorePage[]>("getMorePages");
      if (!previousMorePages) return { previousMorePages: [] };

      const positionMap = new Map(
        newOrder.map((item) => [item.id, item.positionNumber])
      );

      const optimisticMorePages = previousMorePages.map((morePage) => ({
        ...morePage,
        positionNumber: positionMap.get(morePage.id) ?? morePage.positionNumber
      }));

      queryClient.setQueryData("getMorePages", optimisticMorePages);

      return { previousMorePages };
    },
    onSuccess: () => {
      dispatch(
        setNotification({
          message: `More Pages order has been changed successfully!`,
          severity: "success"
        })
      );
      queryClient.invalidateQueries("getMorePages");
    },
    onError: (error: AxiosError, _, context: unknown) => {
      const typedContext = context as MutationContext;

      queryClient.setQueryData("getMorePages", typedContext.previousMorePages);
      dispatch(
        setNotification({
          message: `${error.response?.data.errorMessage}`,
          severity: "error"
        })
      );
    }
  });
}
