import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";

import { ErrorMessage } from "../ErrorMessage";

import OrderingTable from "./OrderingTable";
import { useListMorePages } from "./hooks/useListMorePages";

const OfferPageOrdering = () => {
  const { isLoading, data, error } = useListMorePages();

  if (isLoading) {
    return <CircularProgress data-testid={"loading"} />;
  }

  return (
    <div data-testid="OfferPageOrdering">
      {error && <ErrorMessage axiosErrors={[error as AxiosError]} />}
      {data && <OrderingTable fetchedData={data} />}
    </div>
  );
};

export default OfferPageOrdering;
