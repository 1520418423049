import styled from "@emotion/styled";
import { Chip, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { Draggable } from "react-beautiful-dnd";

import { Article } from "../../../clients/services/sams/content/article/types";
import { Partnership } from "../../../clients/services/sams/content/partnership/types";
import { Promotion } from "../../../clients/services/sams/promotion/types";

const DropTableCell = styled(TableCell)`
  width: 35px;
  font-size: 24px;
  text-align: center;
`;
const ChipTableCell = styled(TableCell)`
  display: table-cell;
  gap: 12px;
`;
const ChipAligned = styled(Chip)`
  margin-right: 10px;
`;

interface OfferElementInterface {
  entity: Promotion | Article | Partnership;
  index: number;
  isMerlin: boolean;
}

const OfferTableCell: FC<OfferElementInterface> = ({
  entity,
  index,
  isMerlin
}) => {
  return (
    <Draggable
      draggableId={
        Object.hasOwn(entity, "slug")
          ? (entity as Promotion).slug
          : ((entity as Partnership).url as string)
      }
      index={index}
    >
      {(innerProvider: any) => (
        <TableRow
          {...innerProvider.draggableProps}
          ref={innerProvider.innerRef}
        >
          <DropTableCell
            data-testid={`DraggableOffer${index}TestID`}
            {...innerProvider.dragHandleProps}
          >
            {" "}
            ={" "}
          </DropTableCell>
          <TableCell data-testid={`DraggableOfferTitle${index}`}>
            {Object.hasOwn(entity, "shortHeadline")
              ? (entity as Promotion | Article).shortHeadline
              : entity.headline}
          </TableCell>
          <ChipTableCell>
            {Object.hasOwn(entity, "promotionType") && (
              <ChipAligned
                color="secondary"
                label={
                  (entity as Promotion).promotionType.charAt(0).toUpperCase() +
                  (entity as Promotion).promotionType.toLowerCase().slice(1)
                }
                variant="filled"
              />
            )}
            <ChipAligned
              color={
                (entity as Promotion).status === "Active"
                  ? "success"
                  : "primary"
              }
              label={(entity as Promotion).status}
              variant="outlined"
            />

            {isMerlin && (
              <ChipAligned
                color="primary"
                label="Merlin Product"
                variant="outlined"
              />
            )}
          </ChipTableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

export default OfferTableCell;
