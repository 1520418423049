import styled from "@emotion/styled";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import { MorePage } from "../../../clients/services/sams/content/morePage";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useChangeMorePagePosition } from "../../OfferPageOrdering/hooks/useChangeMorePagePosition";
import MorePageTableCell from "../MorePageTableCell";

const Wrapper = styled.div`
  padding: 32px 0;
  padding-right: 32px;
`;
const TableContainer = styled(Table)`
  margin-top: 24px;
`;
const StyledButton = styled(Button)`
  margin-left: 32px;
`;
const HeadlineTableCell = styled(TableCell)`
  min-width: 250px;
`;
const TypeTableCell = styled(TableCell)`
  width: 420px;
`;
const TopStatusBar = styled.div`
  margin-top: 24px;
  display: flex;
`;

const transformMorePages = (sortedData: MorePage[]) => {
  return sortedData.map((morePage) => {
    return { id: morePage.id, positionNumber: morePage.positionNumber };
  });
};

interface OrderingTableInterface {
  fetchedData: MorePage[];
}

const OrderingTable: FC<OrderingTableInterface> = ({ fetchedData }) => {
  const [sortedMorePages, setSortedMorePages] = useState<MorePage[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const morePageOrderingMutation = useChangeMorePagePosition();

  useEffect(() => {
    setSortedMorePages(fetchedData);
  }, [fetchedData]);

  const handleDragEnd = (e: DropResult) => {
    if (!e.destination || e.destination.index === e.source.index) return;
    const tempMorePages = Array.from(sortedMorePages);

    const [sourceMorePage] = tempMorePages.splice(e.source.index, 1);
    tempMorePages.splice(e.destination.index, 0, sourceMorePage);

    const updatedMorePages = tempMorePages.map((morePage, index) => ({
      ...morePage,
      positionNumber: index
    }));

    setHasChanges(true);
    setSortedMorePages(updatedMorePages);
  };

  const handleSaveChanges = () => {
    morePageOrderingMutation.mutate(
      transformMorePages(
        sortedMorePages.filter((entity) =>
          Object.hasOwn(entity, "type")
        ) as MorePage[]
      )
    );

    setHasChanges(false);
  };

  return (
    <Wrapper>
      <Typography variant="h4">More Pages Ordering</Typography>
      <TopStatusBar>
        <ConfirmationDialog
          title={`Save Changes`}
          description={`Are you sure you would like to save the order of this list?`}
          actionLabel={`Save changes`}
          action={handleSaveChanges}
        >
          {(handleDialogOpen) => (
            <StyledButton
              color="primary"
              size="medium"
              variant="contained"
              disabled={!hasChanges}
              onClick={handleDialogOpen}
              data-testid={"SaveButtonTestId"}
            >
              Save Changes
            </StyledButton>
          )}
        </ConfirmationDialog>
      </TopStatusBar>

      <TableContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableHead>
            <TableRow>
              <TableCell />
              <HeadlineTableCell>Headline</HeadlineTableCell>
              <TypeTableCell>Type</TypeTableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1">
            {(provider: any) => (
              <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                {sortedMorePages
                  ?.sort((a, b) =>
                    a.positionNumber > b.positionNumber ? 1 : -1
                  )
                  .map((entity: any, index) => (
                    <MorePageTableCell
                      key={`${index}_${entity.slug ? entity.slug : entity.url}`}
                      entity={entity}
                      index={index}
                    />
                  ))}
                {provider.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
    </Wrapper>
  );
};

export default OrderingTable;
